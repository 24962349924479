@use 'shared/shared';
@use '~@nbcnews/design-tokens/tokens/think/breakpoints';
@use '~@nbcnews/design-tokens/tokens/think/colors';
@use '~@nbcnews/design-tokens/tokens/think/colorsRGB';
@use '~@nbcnews/design-tokens/tokens/think/fontFamilies';
@use '~@nbcnews/design-tokens/tokens/think/fontSizes';
@use '~@nbcnews/design-tokens/tokens/think/fontWeights';
@use '~@nbcnews/design-tokens/tokens/think/gradients';
@use '~@nbcnews/design-tokens/tokens/think/letterSpacings';
@use '~@nbcnews/design-tokens/tokens/think/lineHeights';
@use '~@nbcnews/design-tokens/tokens/think/opacities';
@use '~@nbcnews/design-tokens/tokens/think/spacing';

// GLOBAL VARIABLES
$theme: 'think';

// Background
$default-background: colors.$white;

// Colors
$default-color: colors.$red-40; // Historically: burst #ff5042
$default-neutral: colors.$neutral-10;

// Gradient
$default-gradient: to-list(gradients.$black-red-gradient);

// Images
$default-img-border-radius: 0%;

// Links
$default-hover-opacity: opacities.$opacity-70;
$default-active-opacity: opacities.$opacity-50;

// Headlines
$default-headline-color: colors.$grey-70;
$default-headline-leading: lineHeights.$leading-150;
$default-headline-font-family: fontFamilies.$publico-hed;

// Body text
$default-text-size: fontSizes.$text-18;
$default-text-color: colors.$grey-70;
$default-text-leading: lineHeights.$leading-150;
$default-text-font-family: fontFamilies.$publico-txt;

:root {
  /* Colors */
  --default-color: var(--red-40);
  --default-color-rgb: var(--red-40-rgb);
  --default-neutral: var(--neutral-10);
  --default-neutral-rgb: var(--neutral-10-rgb);
  --primary-color: var(--default-color);
  --primary-color-rgb: var(--default-color-rgb);

  /* Gradient */
  --default-gradient: var(--black-red-gradient-stop-1), var(--black-red-gradient-stop-2);

  /* Images */
  --default-img-border-radius: 0%;

  /* Links */
  --default-hover-opacity: var(--opacity-70);
  --default-active-opacity: var(--opacity-50);

  /* Headlines */
  --default-headline-color: var(--grey-70);
  --default-headline-color-rgb: var(--grey-70-rgb);
  --default-headline-leading: var(--leading-150);
  --default-headline-font-family: var(--publico-hed);
  --primary-font: var(--default-headline-font-family);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--publico-txt);
  --secondary-font: var(--default-text-font-family);
}
